<template>
  <div>
    <p class="fw-bold">{{ $t("Step 4: Choose your PIN") }}</p>
    <p class="step3_4__text">
      {{ $t("Please note: do not enter your activation code!") }}
    </p>
    <p>
      {{
        $t(
          "Choose a pin code to secure your MyQii app. You can use the pin code to log in to the MyQii app at a later time."
        )
      }}
    </p>
    <button @click="nextStep" class="btn btn-primary step3_4__btn w-100">
      {{ $t("I have saved a PIN") }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 5 });
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_4 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
    &__btn {
      font-size: 1rem;
    }
  }
}
</style>
