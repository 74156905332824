<template>
  <div class="3">
    <p class="fw-bold">{{ locale.step1.heading }}</p>
    <div class="d-flex justify-content-between align-items-center mb-sm-0 mb-3">
      <div class="text-center">
        <a href="https://apps.apple.com/nl/app/qii/id1495025031" target="_blank">
          <img
            class="step3_1__appStoreImg"
            src="../../../assets/images/common/app-store2x.png"
            alt="app store"
          />
        </a>
        <img
          class="mt-2 d-sm-block d-none mx-auto step3_1__qrCode"
          src="../../../assets/images/qii/QR-apple.svg"
          alt=""
        />
      </div>
      <p class="m-0 step3_1__of d-sm-block d-none">{{ locale.step1.or }}</p>
      <div class="text-center">
        <a
          href="https://play.google.com/store/apps/details?id=com.qii.app&hl=nl&gl=US"
          target="_blank"
        >
          <img
            class="ml-2 step3_1__googlePlayImg"
            src="../../../assets/images/common/google-play2x.png"
            alt="google play"
          />
        </a>
        <img
          class="mt-2 d-sm-block d-none mx-auto step3_1__qrCode"
          src="../../../assets/images/qii/QR-android.svg"
          alt=""
        />
      </div>
    </div>
    <p class="my-3 fw-bold">
      {{ $t("Scan the QR code and download the app in the store") }}
    </p>
    <button @click="nextStep" class="btn btn-primary w-100 step3_1__btn">
      {{ $t("Downloaded the app? Go to next step") }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 2 });
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_1 {
  &__of {
    @include font(1.25rem, $gray, 700);
  }
  &__btn {
    // font-size: 0.85rem;
    font-size: 1rem;
    padding: 1rem;
  }
  &__googlePlayImg,
  &__appStoreImg {
    width: 120px;
    @include xs {
      width: 130px;
    }
  }
  &__qrCode {
    width: 121px;
    height: 121px;
  }
}
</style>
