<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <router-link
        :to="`/user/qiiSteps/3/${tenant.id}`"
        class="btn step2__btn btn-primary d-block w-100 mb-3"
      >
        {{ $t("Next step") }}
      </router-link>
      <router-link class="fw-bold text-gray step2__laterLink d-block w-100" to="/">
        {{ stepLocale.links.later }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("tenant", ["tenant"]),
  },
};
</script>

<style lang="scss">
.step2 {
  &__btn {
    box-shadow: 0px 4px 7px rgba(0, 129, 31, 0.45);
  }
  &__laterLink {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    @include font(1rem, $dark, 500);
    text-align: center;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
</style>
