<template>
  <div class="qiiSteps row">
    <div class="col-md-6 bg-white left p-0">
      <img :src="logo" class="ml-5 pt-4" alt="logo" @click="$router.push('/')" />
      <div
        class="d-flex align-items-center justify-content-center leftContent"
        :class="{ step__step3: $route.params.step.toString() == 3 }"
      >
        <div class="m-auto step">
          <Spinner v-if="!mounted"></Spinner>
          <component
            v-else
            :is="'Step' + stepId"
            :stepLocale="stepLocale"
            @step3-substep-changed="step3Updated"
          />
        </div>
      </div>
    </div>
    <div class="col-md-6 px-0 right">
      <div class="bg-primary text-center text-white py-3 infoBar" v-if="stepId == 3">
        Heb je hulp nodig? Druk in de MyQii-app op het ‘?’ icoon
      </div>
      <RightSection v-if="stepId === 1" :img="img" />
      <RightSection v-if="stepId === 2">
        <div class="step2__right mx-auto">
          <img
            class="mb-3"
            :src="require('../../../assets/images/qii/qiiLogo.png')"
            alt=""
          />
          <p class="step2__right__text">{{ stepLocale.qii.text }}</p>
          <p class="step2__right__subtext">{{ stepLocale.qii.subtext }}</p>
        </div>
      </RightSection>
      <RightSection v-if="stepId === 4">
        <RightPoints active="6" />
      </RightSection>

      <RightSection v-if="stepId === 5" class="step mx-auto pb-4 pb-lg-0">
        <Segment
          :img="step5Img"
          :heading="step5RightContent.heading"
          text="QiiSteps.steps.step5.rightSegment.text"
          :btnText="step5RightContent.btnText"
          to="/user/profile"
          btnVariantClass="btn-white border box-shadow"
        />
      </RightSection>
      <RightSection v-if="stepId === 3">
        <div
          class="d-flex justify-content-center align-items-center text-center step__slider"
        >
          <div class="w-100 text-center position-relative step__slider__content">
            <img
              class="step__slider__mobile"
              :class="{ alternate: activeIndex == 2 }"
              :src="require('../../../assets/images/qii/mobile2x.png')"
              alt=""
            />

            <Swiper
              @slideChange="onSlideChange"
              ref="mySwiper"
              class="swiper"
              :options="swiperOptions"
            >
              <SwiperSlide class="cursor-pointer" v-for="(slide, i) in slides" :key="i">
                <img class="step__slider__img" :src="slide.img" alt="" />
              </SwiperSlide>
            </Swiper>
            <button
              class="btn p-0 btn-primary swiper-button-prev d-sm-flex d-none"
              slot="button-prev"
            >
              <img
                class="arrow-img"
                :src="require('../../../assets/images/common/left-arrow.svg')"
                alt=""
              />
            </button>
            <button
              class="btn p-0 btn-primary swiper-button-next d-sm-flex d-none"
              slot="button-next"
            >
              <img
                class="arrow-img"
                :src="require('../../../assets/images/common/right-arrow.svg')"
                alt=""
              />
            </button>
            <div
              class="swiper-pagination step__slider__pagination mx-auto"
              slot="pagination"
            ></div>
          </div>
        </div>
      </RightSection>
    </div>
  </div>
</template>

<script>
import CardHeading from "@/components/general/CardHeading.vue";
import Step1 from "@/components/QiiSteps/Step1.vue";
import Step2 from "@/components/QiiSteps/Step2.vue";
import Step3 from "@/components/QiiSteps/Step3.vue";
import Step4 from "@/components/QiiSteps/Step4.vue";
import Step5 from "@/components/QiiSteps/Step5.vue";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";

import Segment from "../../../components/QiiSteps/step5/Segment.vue";

import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import RightPoints from "../Expat/RightPoints.vue";
import { mapActions, mapGetters } from "vuex";
import Spinner from "../../../components/ui/Spinner.vue";
export default {
  components: {
    CardHeading,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    RightSection,
    AuthLayoutContent,
    Segment,
    Swiper,
    SwiperSlide,
    RightPoints,
    Spinner,
  },
  data() {
    return {
      logo: require("../../../assets/images/common/logo-ETH.svg"),
      img: require("../../../assets/images/qii/step1.svg"),
      step4Img: require("../../../assets/images/qii/select-house.png"),
      step5Img: require("../../../assets/images/auth/register.svg"),
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
      },
      mounted: false,
      activeIndex: 1,
    };
  },
  async mounted() {
    this.activeIndex = this.$route.query.substep || 1;
    if (this.$refs.mySwiper) {
      this.$refs.mySwiper.$swiper.slideTo(this.activeIndex);
    }
    if (!this.$route.query.substep) {
      this.$router.replace({
        path: this.$route.path,
        query: { substep: 1 },
      });
    }
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
    this.mounted = true;
  },

  computed: {
    ...mapGetters("tenant", ["tenant"]),
    slides() {
      const slidesArr = [
        { img: require("../../../assets/images/qii/slide1.png"), number: 1 },
        { img: require("../../../assets/images/qii/slide2.png"), number: 2 },
        { img: require("../../../assets/images/qii/slide3.png"), number: 3 },
        { img: require("../../../assets/images/qii/slide4.png"), number: 4 },
        { img: require("../../../assets/images/qii/slide5.png"), number: 5 },
        { img: require("../../../assets/images/qii/slide6.png"), number: 6 },
        { img: require("../../../assets/images/qii/slide7.png"), number: 7 },
        { img: require("../../../assets/images/qii/slide8.png"), number: 8 },
        { img: require("../../../assets/images/qii/slide9.png"), number: 9 },
      ];
      if (this.$store.state.numberOfTennants < 2) {
        slidesArr.pop();
      }
      return slidesArr;
    },
    stepId() {
      const step = +this.$route.params.step;
      if (isNaN(step)) return 5;
      return step;
    },
    stepLocale() {
      return this.$t("QiiSteps.steps.step" + this.stepId);
    },
    step5RightContent() {
      return this.stepLocale.rightSegment;
    },
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    gotoStep(step) {
      this.$router.replace({
        path: this.$route.path,
        query: { substep: step },
      });
    },
    onSlideChange() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    step3Updated(step) {
      this.$refs.mySwiper.$swiper.slideTo(step);
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 442px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__step3 {
    // height: 900px;
    @include md {
      padding-top: 0;
      align-items: flex-start !important;
      height: auto;
      padding-bottom: 50px;
    }

    @include xs {
      padding-bottom: 0px;
    }

    &__tabs {
      width: 35px;
      height: 6px;
      background: #d9d9d9;
      border-radius: 50px;
      margin-right: 3px;
    }

    &__tabs.active {
      background-color: $primary;
    }
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__slider {
    width: 350px;
    padding-bottom: 20px;
    margin: auto;

    @include md {
      margin-top: 70px;
      padding-bottom: 0px;
    }

    @include xs {
      margin-top: 30px;
    }

    @include customMax(375px) {
      overflow: hidden;
    }

    &__img {
      width: 282px;
    }

    &__content {
      @include md {
      }

      @include xs {
      }
    }

    &__step2Content {
      &__orangeBox {
        width: 238px;
        height: 57px;
        background: #d65543;
        border-radius: 5px;
      }
    }

    &__mobile {
      position: absolute;
      top: -10px;
      left: 16.3px;
      width: 316.5px;

      @include xs {
        top: -12px;
        width: 315.5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__mobile.alternate {
      top: -14px;
      width: 316px;

      @include xs {
        top: -12px;
        width: 315.5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__qiiLogo {
      margin-bottom: 50px;
      width: 168px;
    }

    &__qiiLogo.alternate {
      margin-bottom: 10px;
    }

    &__pagination {
      bottom: -40px;
      width: 142px;
      display: flex;
      justify-content: space-between;
      left: 50%;
      transform: translateX(-50%);

      @include md {
        bottom: -40px;
      }

      @include xs {
        display: none;
      }
    }
  }
}

.step2__right {
  width: 412px;

  @include xs {
    width: 100%;
    padding: 25px 15px 34px;
    background: $right-section-bg;
    min-height: 360px;
  }

  &__text {
    @include font(1rem, $alreadyAccountTextColor, 400);
  }

  &__subtext {
    @include font(1rem, $primary, 700);
  }
}

.swiper-pagination-bullet {
  background: $primary;
}

.swiper-button-prev,
.swiper-button-next {
  border-radius: 50%;
  width: 63px;
  height: 63px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}

.swiper-button-prev {
  left: -70px;

  @include lg {
    left: -50px;
  }
}

.swiper-button-next {
  right: -70px;

  @include lg {
    right: -50px;
  }
}

.arrow-img {
  width: 28px;
  height: 28px;
}

.qiiSteps {
  &.row {
    min-height: 100vh;
  }
  .leftContent {
    height: calc(100% - 54px);
  }

  .infoBar {
    position: absolute;
    width: 100%;
  }
}
</style>
