<template>
  <div class="segment">
    <div class="text-center mb-4">
      <img class="segment__img" :src="img" alt="illustration" />
    </div>
    <CardHeading :heading="heading" class="mb-2" />
    <i18n :path="text" class="fs-18 text-dark mb-4" tag="p">
      <br class="d-none d-sm-block" />
    </i18n>

    <RouterLink :to="to" class="btn box-shadow d-block w-100" :class="[btnVariantClass]">{{ btnText }}</RouterLink>
  </div>
</template>

<script>
import CardHeading from "../../general/CardHeading.vue";
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    btnVariantClass: {
      type: String,
      default: "btn-primary",
    },
  },
  components: { CardHeading },
};
</script>

<style lang="scss">
.segment {
  &__img {
    max-width: 240px;
  }
}
</style>
