<template>
  <div>
    <p class="fw-bold">{{ $t("Step 2: Open the MyQii app and create an account") }}</p>
    <ul>
      <li class="my-2 step3_2__bullet">{{ $t("Open the MyQii app") }}</li>
      <li class="my-2 step3_2__bullet">
        {{ $t("Follow the introductions by pressing 'Next'") }}
      </li>
      <li class="my-2 step3_2__bullet">
        {{ $t("Do you agree with the conditions of Qii? Press 'Start'") }}
      </li>
      <li class="my-2 step3_2__bullet">
        {{ $t("No Qii account yet? Press create MyQii account") }}
      </li>
      <li class="my-2 step3_2__bullet">
        {{
          $t(
            "Already an account? Then press log in with your account and follow the steps"
          )
        }}
      </li>
    </ul>
    <button @click="nextStep" class="btn btn-primary step3_2__btn w-100 my-3">
      {{ $t("I want to enter the activation code") }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 3 });
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_2__bullet {
  list-style: inside;
  @include font(1rem, #373737, 500);
  &__btn {
    font-size: 1rem;
  }
}
</style>
