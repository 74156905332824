<template>
  <div class="step3 mt-5">
    <h1>{{ $t("Create a MyQii") }}</h1>
    <ul class="p-0 mt-4">
      <li v-for="step in steps" :key="step.step" class="my-2">
        <p
          @click="nextStep({ step: step.step })"
          v-if="step.step != currentStep"
          class="text-gray cursor-pointer d-sm-block d-none"
        >
          {{ step.heading }}
        </p>
        <KeepAlive>
          <component
            v-if="currentStep == step.step"
            @nextStep="nextStep"
            @codeFetched="onCodeFetched"
            :is="step.component"
            :key="step.component"
            :stepLocale="locale"
            :fetchCode="fetchCode"
          />
        </KeepAlive>
        <hr class="d-sm-block d-none" />
      </li>
    </ul>
  </div>
</template>

<script>
// import SocketioService from "@/services/socketio.service.js";
import step1 from "./step3/step3-1.vue";
import step2 from "./step3/step3-2.vue";
import step3 from "./step3/step3-3.vue";
import step4 from "./step3/step3-4.vue";
import step5 from "./step3/step3-5.vue";
import step6 from "./step3/step3-6.vue";
import step7 from "./step3/step3-7.vue";
import step8 from "./step3/step3-8.vue";
import step9 from "./step3/step3-9.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    step7,
    step8,
    step9,
  },
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
      fetchCode: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["getAccessToken", "user"]),
    steps() {
      const stepsArr = [
        {
          step: 1,
          heading: this.$t("Step 1: Download the MyQii app on your mobile phone"),
          component: "step1",
        },
        {
          step: 2,
          heading: this.$t("Step 2: Open the MyQii app and create an account"),
          component: "step2",
        },
        {
          step: 3,
          heading: this.$t("Step 3: Enter the activation code"),
          component: "step3",
        },
        {
          step: 4,
          heading: this.$t("Step 4: Choose your PIN"),
          component: "step4",
        },
        {
          step: 5,
          heading: this.$t("Step 5: Enter your contact details"),
          component: "step5",
        },
        {
          step: 6,
          heading: this.$t("Step 5: Enter your contact details"),
          component: "step6",
        },
        {
          step: 7,
          heading: this.$t("Step 7: Collect data with DigiD"),
          component: "step7",
        },
        {
          step: 8,
          heading: this.$t("Step 8: Share your data"),
          component: "step8",
        },
        {
          step: 9,
          heading: this.$t("Step 9: Send invitation to other main residents"),
          component: "step9",
        },
      ];
      if (this.$store.state.numberOfTennants < 2) {
        stepsArr.pop();
      }
      return stepsArr;
    },
    locale() {
      return this.$t("QiiSteps.steps");
    },
  },
  watch: {
    // for mobile view

    "$route.query"(query) {
      let screen = window.matchMedia("(max-width: 575px)").matches;
      if (screen && query.substep) {
        this.currentStep = +query.substep;
        this.$emit("step3-substep-changed", +query.substep);
      }
    },
  },

  methods: {
    ...mapActions("user", ["getUser"]),
    nextStep(data) {
      // this.current = data.name;
      this.currentStep = data.step;
      this.$router.replace({
        path: this.$route.path,
        query: { substep: data.step },
      });
      this.$emit("step3-substep-changed", data.step);
    },
    onCodeFetched() {
      this.fetchCode = false;

      const interval = setInterval(async () => {
        let data = await this.getUser();
        if (data.user.householdId) {
          this.$router.push("/user/qiiSteps/4");
          clearInterval(interval);
        }
      }, 3000);
    },
  },

  created() {
    this.currentStep = this.$route.query.substep || 1;
    this.$emit("totalsteps", this.steps);
    // SocketioService.setupSocketConnection(this.getAccessToken);
    // SocketioService.socket.on("household-status-updated", () => {
    //   console.log('SOCKET EMITTING HOUSEHOLD STATUS')
    //   if (this.$route.query.updating) {
    //     this.$router.push("/user/profile?updated=true");
    //   } else {
    //     this.$router.push("/user/qiiSteps/4");
    //   }
    // });
  },
  beforeUnmount() {
    // SocketioService.disconnect();
  },
};
</script>

<style></style>
