<template>
  <div>
    <p class="fw-bold">{{ $t("Step 7: Collect data with DigiD") }}</p>
    <p class="step3_7__text">
      {{
        $t(
          "To retrieve your data, you must first log in with your DigiD username and password. It is not yet possible to log in via the DigiD app."
        )
      }}
    </p>
    <button @click="nextStep" class="btn btn-primary step3_7__btn w-100">
      {{ $t("I'm ready to share my data") }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 8 });
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_7 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
  &__btn {
    font-size: 1rem;
  }
}
</style>
