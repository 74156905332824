<template>
  <div>
    <p class="fw-bold">{{ $t("Step 9: Send invitation to other main residents") }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_9 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
}
</style>
