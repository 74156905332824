<template>
  <div>
    <p class="fw-bold">{{ $t("Step 8: Share your data") }}</p>
    <p class="step3_8__text">
      {{
        $t(
          "When you have retrieved all the necessary data, you can check it again. Is everything correct? Then you can send the data by pressing 'Share data'."
        )
      }}
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_8 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
}
</style>
