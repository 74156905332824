<template>
  <div>
    <h1>{{ $t("Sharing your details using MyQii") }}</h1>
    <!-- <div class="d-flex d-sm-none">
      <span
        v-for="slide in slides"
        :key="slide.number"
        @click="gotoStep(slide.number)"
        class="step__step3__tabs"
        :class="{
          active: $route.query.substep.toString() === slide.number.toString(),
        }"
      >
      </span>
    </div> -->
    <p class="fw-medium text-gray">
      {{
        $t(
          "You are about to retrieve and store your data by using MyQii. This step requires both a computer and a phone. If this does not work, return to choose another method."
        )
      }}
    </p>
    <ul class="p-0 mb-3">
      <li class="fw-bold mb-2">
        <ExclamationCircle />
        <span class="ml-2 step1__point">{{ $t("Have your phone ready") }}</span>
      </li>
    </ul>
    <p class="step1__tip">
      {{ $t("Tip: it is easier if the other main tenants also register immediately.") }}
    </p>
    <div class="d-flex flex-column align-items-center">
      <router-link
        :to="`/user/qiiSteps/3/${tenant.id}`"
        class="d-block w-100 btn btn-primary px-4 step1__nextBtn"
      >
        {{ $t("Next step") }}
      </router-link>
      <router-link class="btn btn-white btn-block mt-3" to="/">
        {{ "Choose other method" }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExclamationCircle from "../svg/ExclamationCircle.vue";
export default {
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  components: { ExclamationCircle },
  computed: {
    ...mapGetters("tenant", ["tenant"]),
  },
};
</script>

<style lang="scss">
.step1 {
  &__laterLink {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    @include font(1rem, $dark, 500);
    text-align: center;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-top: 19px;
  }
  &__nextBtn {
    box-shadow: 0px 4px 7px rgba(0, 129, 31, 0.45);
  }
  &__tip {
    @include font(1rem, #016fff, 700);
  }
  &__point {
    @include font(1rem, $dark, 700);
  }
}
</style>
