<template>
  <Segment
    :img="img"
    :heading="content.heading"
    text="QiiSteps.steps.step5.leftSegment.text"
    :btnText="content.btnText"
    to="/listings?page=1&type=nonSuitable&limit=10&sort=created_at"
  />
</template>

<script>
import Segment from "./step5/Segment.vue";
export default {
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  components: {
    Segment,
  },
  data() {
    return {
      img: require("../../assets/images/qii/select-house.png"),
    };
  },
  computed: {
    content() {
      return this.stepLocale.leftSegment;
    },
  },
};
</script>
