<template>
  <div>
    <!-- <div class="d-flex align-items-center justify-content-center mt-3">
      <h2 class="text-underline fw-bold mb-0 text-underline">
        {{ getCode }}
      </h2>
      
    </div> -->

    <p class="fw-bold">{{ $t("Step 3: Enter the activation code") }}</p>
    <p class="step3_3__text">{{ locale.step3.text }}</p>
    <div class="step3_3__codeBox text-center" v-if="code">
      {{ code }}
    </div>
    <div class="d-flex align-items-center justify-content-center my-3">
      <p class="step3_3__textBelowCode m-0">{{ $t("request new code") }}</p>
      <button class="bg-transparent border-0 ml-2" @click="getCode(tenant.id)">
        <img
          :class="{ 'rotation-animation': getLoadings.qii }"
          src="../../../assets/images/common/refresh.png"
          alt="refresh"
        />
      </button>
    </div>
    <button @click="nextStep" class="btn btn-primary step3_3__btn w-100">
      {{ $t("I have entered the code") }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("qii", ["code"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("tenant", ["tenant"]),
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    ...mapActions("qii", ["getCode"]),
    nextStep() {
      this.$emit("nextStep", { step: 4 });
    },
  },
  async mounted() {
    if (this.fetchCode) {
      await this.getCode(this.tenant.id);
      this.$emit("codeFetched", false);
    }
  },
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
    fetchCode: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_3 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
  &__codeBox {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    @include font(3.125rem, #000000, 700);
    border: 2px solid $primary;

    border-radius: 5px;
  }
  &__textBelowCode {
    @include font(1rem, $primary, 500);
  }
  &__btn {
    font-size: 1rem;
  }
}
</style>
