<template>
  <div>
    <p class="fw-bold">{{ $t("Step 6: Family and financial situation") }}</p>
    <p class="step3_6__text">
      {{
        $t(
          "Select your family composition and the number of children living at home. Go to the next step in the MyQii app."
        )
      }}
    </p>
    <p class="step3_6__text">
      {{ $t("Select your income type. Equity does not have to be entered.") }}
    </p>
    <p class="step3_6__text">
      {{ $t("Follow the steps within the MyQii app.") }}
    </p>
    <button @click="nextStep" class="btn btn-primary step3_6__btn w-100">
      {{ $t("Retrieve data with DigiD") }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 7 });
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_6 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
  &__btn {
    font-size: 1rem;
  }
}
</style>
