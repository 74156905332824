<template>
  <div>
    <p class="fw-bold">{{ $t("Step 5: Enter your contact details") }}</p>
    <p class="step3_5__text">
      {{ $t("Enter your e-mail address and telephone number and press 'Next step'.") }}
    </p>
    <p class="step3_5__text">
      {{
        $t(
          "In the next screen you have to enter a pin code of 5 digits. This way your data is protected when you open the MyQii app at a later time."
        )
      }}
    </p>
    <p class="step3_5__text">
      {{
        $t(
          "After you have created a pin code, a confirmation will be shown that your account has been successfully created."
        )
      }}
    </p>
    <button @click="nextStep" class="btn step3_5__btn btn-primary w-100">
      {{ $t("I have created a MyQii account") }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.stepLocale.step3;
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { step: 6 });
    },
  },

  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.step3_5 {
  &__text {
    @include font(1rem, #454545, 500);
    margin-top: 14px;
    margin-bottom: 23px;
  }
  &__btn {
    padding: 1rem 0.6rem;
    font-size: 1rem;
  }
}
</style>
