<template>
  <div>
    <p class="text-danger fw-bold text-capitalize" v-if="getErrors.profile">
      {{ getErrors.profile }}
    </p>
    <Form :formFields="formFields" @on-submit="onSubmit">
      <template v-slot:formfooter>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary btn-block w-100 ml-auto mb-5">
              {{ stepLocale.btnText }}
              <b-spinner
                v-if="getLoadings.profile"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </button>
          </div>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import { mapActions, mapGetters } from "vuex";
import preferredMunicipality from "@/constants/municipality";

export default {
  props: {
    stepLocale: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),

    formFields() {
      return [
        {
          name: "preferredHousingType",
          component: "MultiselectInput",
          value: [],
          rules: "required",
          placeholder: this.$t("profile.preferences.housingType[0]"),
          label: this.$t("profile.preferences.housingType[0]"),
          colClasses: "col-12 pb-0",
          multiple: true,
          searchable: false,
          options: [
            {
              text: this.$t("profile.preferences.housingType[1]"),
              value: "house",
            },
            {
              text: this.$t("profile.preferences.housingType[2]"),
              value: "appartment",
            },
            {
              text: this.$t("profile.preferences.housingType[3]"),
              value: "studio",
            },
            {
              text: this.$t("profile.preferences.housingType[4]"),
              value: "room",
            },
          ],
        },
        {
          name: "householdComposition",
          component: "SelectInput",
          value: "",
          rules: "required",
          placeholder: this.$t("profile.household.composition"),
          label: this.$t("profile.household.composition"),
          colClasses: "col-12 pt-0 pb-0",
          options: [
            {
              text: this.$t("profile.household.composition"),
              value: "",
              disabled: true,
              hidden: true,
            },
            {
              text: this.$t("householdComposition[0]"),
              value: "single",
            },
            {
              text: this.$t("householdComposition[1]"),
              value: "couple",
            },
            {
              text: this.$t("householdComposition[2]"),
              value: "family",
            },
            {
              text: this.$t("householdComposition[3]"),
              value: "group occupancy",
            },
          ],
        },
        {
          name: "preferredMunicipality",
          placeholder: this.$t("profile.preferences.municipality"),
          label: this.$t("profile.preferences.municipality"),
          component: "MultiselectInput",
          rules: "required",
          value: [],
          colClasses: "col-12 pb-0 pt-0",
          multiple: true,
          searchable: true,
          options: preferredMunicipality,
        },
        {
          name: "minLivingSpace",
          type: "text",
          placeholder: this.$t("profile.preferences.minLS"),
          label: this.$t("profile.preferences.minLS"),
          component: "TextInput",
          value: "",
          colClasses: "col-6 pb-0 pt-0",
          rules: "required",
          masked: true,
          mask: [
            {
              mask: "",
            },
            {
              mask: "num m²",
              placeholderChar: "Min. living space",
              lazy: false,
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
        },
        {
          name: "maxLivingSpace",
          type: "text",
          placeholder: this.$t("profile.preferences.maxLS"),
          label: this.$t("profile.preferences.maxLS"),
          component: "TextInput",
          value: "",
          classes: "col-12 col-lg-6",
          colClasses: "col-6 pb-0 pt-0",
          rules: "required",
          masked: true,
          mask: [
            {
              mask: "",
            },
            {
              mask: "num m²",
              lazy: false,
              blocks: {
                num: {
                  mask: Number, // enable number mask
                  scale: 2,
                  signed: false,
                  thousandsSeparator: ".",
                  padFractionalZeros: false,
                  normalizeZeros: true,
                  radix: ",",

                  // additional number interval options (e.g.)
                  min: 0,
                  max: 99999999,
                },
              },
            },
          ],
        },
        {
          name: "minRooms",
          type: "text",
          placeholder: this.$t("profile.preferences.minRooms"),
          label: this.$t("profile.preferences.minRooms"),
          component: "TextInput",
          value: null,
          classes: "col-12 col-lg-6",
          colClasses: "col-6 pb-0 pt-0",
          rules: "required|numeric",
        },
        {
          name: "maxRooms",
          type: "text",
          placeholder: this.$t("profile.preferences.maxRooms"),
          label: this.$t("profile.preferences.maxRooms"),
          component: "TextInput",
          rules: "required|numeric",
          value: null,
          classes: "col-12 col-lg-6",
          colClasses: "col-6 pb-0 pt-0",
        },
        {
          name: "pets",
          component: "SelectInput",
          value: null,
          rules: "required",
          placeholder: this.$t("Pets"),
          label: this.$t("Pets"),
          colClasses: "col-12 pb-0 pt-0",
          options: [
            {
              text: this.$t("profile.household.pets[1]"),
              value: true,
            },
            {
              text: this.$t("profile.household.pets[2]"),
              value: false,
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions("user", ["updateUser"]),
    async onSubmit(data) {
      try {
        const formattedData = { ...data };
        formattedData.preferredHousingType = data.preferredHousingType.map(
          (type) => type.value
        );
        formattedData.preferredMunicipality = data.preferredMunicipality.map(
          (type) => type.value
        );

        await this.updateUser({ body: formattedData });
        this.$router.push("/user/qiiSteps/final");
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
